<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:52:34
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-12 17:16:48
 * @FilePath: \nc-funeral-mobile\src\views\Index.vue
-->
<template>
  <div>
    <router-view  />
    <!-- 导航栏 -->
    <van-tabbar route v-model="active" @change="tabChange" active-color="#78bca5" inactive-color="#a1a4b2">
      <van-tabbar-item
        v-for="(item, index) in menuList"
        :key="index"
        :to="item.path"
      >
        <span
          v-if="index != 2"
          
          >{{ item.name }}</span
        >
        <template #icon="props" v-if="index != 2">
          <img class="all-icon" :src="props.active ? item.active : item.normal" />
        </template>
        <!-- 中间查进度按钮 -->
        <template v-if="index == 2">
          <img class="progress-icon" :src="item.normal" />
        </template>
        <div
          v-if="index == 2"
          :class="active == index ? 'tab-text active-text progress-text' : 'tab-text progress-text'"
          >{{ item.name }}</div
        >
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      active: 0,
      menuList: [
        {
          name: "首页",
          path: "/home",
          normal: require("../assets/images/tabbar/home.png"),
          active: require("../assets/images/tabbar/home-active.png"),
        },
        {
          name: "导航",
          path: "/navigator/navigator",
          normal: require("../assets/images/tabbar/navigator.png"),
          active: require("../assets/images/tabbar/navigator-active.png"),
        },
        {
          name: "查进度",
          normal: require("../assets/images/tabbar/qrcode.png"),
        },
        {
          name: "预约",
          path: "/reserve/reserve",
          normal: require("../assets/images/tabbar/reserve.png"),
          active: require("../assets/images/tabbar/reserve-active.png"),
        },
        {
          name: "我的",
          path: "/mine/mine",
          normal: require("../assets/images/tabbar/me.png"),
          active: require("../assets/images/tabbar/me-active.png"),
        },
      ],
    };
  },
  created() {},
  methods: {
    tabChange(v) {
      this.active = v;
      if(v==2){
        this.$router.push({path:'/scan/scan'})
      }
    },
  },
};
</script>
<style lang="less">
@import '../assets/styles/index.less';
.tab-text {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #a1a4b2;
}
.active-text {
  color: #78bca5;
}
.all-icon{
  width: 30px;
  height: 30px !important;
}

.progress-icon {
  width: 44px;
  height: 42px;
  position: absolute;
  top: -18px;
}
.progress-text{
  width: 44px;
  text-align: center;
  margin-top: 25px;
}
.van-tabbar-item {
  position: relative;
  font-size: 11px !important;
  font-family: PingFang SC;
  font-weight: 500;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0 !important;
}
.van-tabbar {
  border-top: 1px solid #eee;
  height: 60px !important;
}
</style>


